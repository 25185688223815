<!-- Hero Start -->

<section class="bg-half-170 d-table w-100  overflow-hidden" id="home" style="padding-bottom: 20px !important; padding-top: 100px;">
    
    <div class="contenedor">
        <img src="assets/images/psicologia-bilbao/01_Psicologo_Bilbao_Titulo.webp" alt="Imagen de fondo" class="image">
        <div class="overlay">
          <h2 class="headline">Médicos Bilbao</h2>
          <h1 class="headline">Consulta de Psicología en Bilbao</h1>
        </div>
      </div>


    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-md-12">
                <div class="title-heading">
                   <p>
                    ¿Tienes algún problema emocional, afectivo, familiar o social que te impide tener una felicidad plena?
                    Nuestra psicóloga en Bilbao te ayudará en el camino hacia una vida más plena y satisfactoria.</p>
                    <h2>PSICÓLOGA EN BILBAO</h2>
                    
                    <p>
                    En nuestra consulta de psicología en Bilbao, nuestra psicóloga <strong>Miriam Turrado</strong> podrá ayudarte a:</p>
                    
                    <ul  class="list-group list-group-flush">
                    <li class="list-group-item">Superar la ansiedad, el estrés y la depresión.</li>
                    <li class="list-group-item">Mejorar tus relaciones personales y familiares.</li>
                    <li class="list-group-item">Aumentar tu autoestima y confianza en ti mismo.</li>
                    <li class="list-group-item">Gestionar las emociones difíciles.</li>
                    <li class="list-group-item">Desarrollar habilidades para enfrentar los retos de la vida.</li>
                    <li class="list-group-item">No dudes en contactarnos. Un futuro más positivo te espera.</li>
                </ul>
                
                </div>
            </div>
           
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section bg-light" style="padding-top: 20px !important; padding-bottom:20px !important;">
    <div class="container">
        <div class="row">           

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    
                    <div class="card-body p-0 content">
                        <h5>Centro <br> Médicos Bilbao</h5>

                        <a href="https://medicosbilbao.com/"  target="_blank" class="btn btn-pills btn-soft-success mt-4">Ir a la web</a>         
                        
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Nuestra <br>Psicóloga</h5>                        

                        <a href="https://medicosbilbao.com/quienes-somos/miriam-turrado-psicologa-bilbao" target="_blank" class="btn btn-pills btn-soft-success mt-4">Miriam Turrado</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Dónde<br> Estamos</h5>
                              <a href="https://medicosbilbao.com/contactar" target="_blank" class="btn btn-pills btn-soft-success mt-4">Ver Mapa</a>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Reservar <br>Cita</h5>                        

                            <a href="https://medicosbilbao.com/contactar" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal"
                            data-bs-target="#appointment-request">Ir a contactar</a>

                       
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">

    <div class="container ">
        <h4 class="title mb-4 text-center">CONSULTA DE PSICOLOGÍA EN BILBAO</h4>

        <p class="text-center lead">Ofrecemos un espacio seguro y confidencial para abordar tus inquietudes y trabajar junto a nuestra <strong>psicóloga</strong> hacia una vida más plena y satisfactoria.
            Podrás encontrar nuestra <strong>consulta de psicología en Bilbao</strong> en Gran Vía Don Diego López de Haro 81, 3ª planta.  Te será fácil llegar hasta nosotros, ya que estamos muy cerca del metro, tranvía, autobús, parada de taxi y aparcamiento público. 
            </p>
      
                          <div class="row">
                              <div class="col-lg-6 order-2 order-lg-1 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-right py-2 ms-auto">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0">Psicóloga en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"   >
                                                    En nuestra consulta de psicología en Bilbao te ofrecemos un espacio seguro y confidencial para tratar tus inquietudes y ayudarte a superar los problemas que te preocupan a ti, a tu pareja o a tu familia.       Nuestra psicóloga en Bilbao te acompañará en un proceso personalizado para comprender y gestionar tus emociones, mejorar tus relaciones, superar dificultades, aumentar tu autoconocimiento, desarrollar habilidades y alcanzar tus objetivos.
                                                    
                                                        <p class="text-3-5 pb-2 text-justify"     >        Juntos trabajaremos para mejorar tu bienestar emocional                                                        </p>                    
                                                  
                                                  <a href="https://medicosbilbao.com/especialidades/psicologo-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-6 order-1 order-lg-2 p-0">
                                  
                                  <img src="assets/images/psicologia-bilbao/02.Psicologo_Bilbao_jpeg.webp" class="img-fluid" alt="Psicóloga en Bilbao" loading="lazy">
                                 
                              </div>
                          </div>	
      
                          <div class="row" >                    
                              <div class="col-lg-6 p-0">
                                 
                                  <img src="assets/images/psicologia-bilbao/03.Psicologo_bilbao_infantil.webp" class="img-fluid" alt="Psicóloga juvenil" loading="lazy">
      
                              </div>
                              <div class="col-lg-6 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-left py-2">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0   ">Psicóloga Infanto-Juvenil en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"     > En nuestra consulta de psicología infantil en Bilbao podemos ayudarte a superar desafíos emocionales y conductuales, fomentar un desarrollo saludable y mejorar tus habilidades sociales. </p>
                                                  <p class="text-3-5 pb-2 text-justify"     >Nuestra psicóloga infantil en Bilbao trata problemas como ansiedad, depresión, dificultades escolares y conflictos familiares. A través de terapias personalizadas, proporciona un espacio seguro para que los jóvenes expresen sus sentimientos, desarrollen resiliencia y adquieran herramientas para enfrentar el futuro con confianza y bienestar.
                                                    
                                                    </p>
                  
                                                  <a href="https://medicosbilbao.com/especialidades/fisioterapeuta-deportivo-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>     
                      </div>
    

    <div class="container mt-20">
        <div class="row align-items-end mb-4 pb-2">
            <div class="col-md-10">
                <div class="section-title  text-md-start">
                    
                  
                   <h2 class="text-center ">QUÉ TRATAMOS EN NUESTRA CONSULTA DE FISIOTERAPIA EN BILBAO</h2>

                 
                </div>
            </div>
            <!--end col-->

           
            <!--end col-->
        </div>
        <!--end row-->


        <div class="row"> 
            <div >
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="nav nav-pills rounded shadow p-3 mb-0 col-md-12 list-inline  list-inline-columns" orientation="horizontal">
                    <li ngbNavItem="top" class="nav-item list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Terapia Individual</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/psicologia-bilbao/04.Psicologo_bilbao_terapia_individual.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Terapia Individual">
                                <div class="mt-4">
                                    <h5>Terapia Individual</h5>
                                    <p class="text-muted my-3">En las sesiones de <strong>terapia individual</strong> en nuestra <strong>consulta de psicología en Bilbao</strong>, nuestra psicóloga tratará principalmente de:</p>
                                    <ul>
                                        <li>
                                            <p><strong>Conocer tu situación y necesidades</strong>: estableciendo un vínculo de confianza y seguridad contigo para poder saber cómo ayudarte.</p>
                                        </li>
                                        <li>
                                            <p><strong>Abordar problemas emocionales y de comportamiento</strong>: como ansiedad, depresión, traumas, fobias, baja autoestima y dificultades en las relaciones.</p>
                                        </li>
                                        <li>
                                            <p><strong>Mejorar el autoconocimiento</strong>: comprender emociones, pensamientos y patrones de conducta.</p>
                                        </li>
                                        <li>
                                            <p><strong>Desarrollar habilidades para enfrentar problemas</strong>: manejo del estrés, resolución de problemas y toma de decisiones.</p>
                                        </li>
                                        <li>
                                            <p><strong>Establecer metas y objetivos personales</strong>: y crear un plan para alcanzarlos.</p>
                                        </li>
                                        <li>
                                            <p><strong>Buscar cómo aumentar el bienestar general</strong>: planteando estrategias que busquen mejorar la calidad de vida y la satisfacción personal.</p>
                                        </li>
                                    </ul>
                             
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    
                    <li ngbNavItem="middle" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Terapia de pareja</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/psicologia-bilbao/05.Psicologo_bilbao_terapia_pareja.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Terapia de pareja">
                                <div class="mt-4">
                                    <h5>Terapia de pareja</h5>
                                   

                                    <p class="text-muted my-3">La <strong>terapia de pareja</strong> no es una solución mágica, pero puede ser una herramienta valiosa para parejas que buscan mejorar su relación. Nuestra psicóloga en Bilbao os ofrece:</p>
                                    <ul>
                                        <li>
                                            <p><strong>Un espacio seguro y confidencial</strong> para la comunicación honesta y sin juicios.</p>
                                        </li>
                                        <li>
                                            <p><strong>Mejorar la comunicación</strong> enseñando habilidades de escucha asertiva y expresión clara de necesidades.</p>
                                        </li>
                                        <li>
                                            <p><strong>Resolver conflictos</strong> de manera constructiva y llegar a acuerdos satisfactorios para ambos.</p>
                                        </li>
                                        <li>
                                            <p><strong>Fortalecer la relación</strong> reavivando la conexión emocional, aumentando la intimidad y el compromiso.</p>
                                        </li>
                                        <li>
                                            <p><strong>Identificar y abordar problemas subyacentes</strong> que puedan estar afectando la relación.</p>
                                        </li>
                                        <li>
                                            <p><strong>Enseñar habilidades para el manejo del estrés</strong> individual y como pareja.</p>
                                        </li>
                                        <li>
                                            <p><strong>Prevenir rupturas</strong> ayudando a la pareja a tomar decisiones informadas sobre el futuro de su relación.</p>
                                        </li>
                                    </ul>                                 
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Terapia Familiar</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow  show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/psicologia-bilbao/06.Psicologo_bilbao_terapia_familia.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Terapia Familiar">
                                <div class="mt-4">
                                    <h5>Terapia Familiar</h5>
                                    
                                        <p class="text-muted my-3">La <strong>terapia familiar</strong> busca soluciones para que la familia funcione mejor en conjunto. En nuestra consulta de psicología familiar, nuestra psicóloga os ayudará a:</p>
                                        <ul>
                                            <li>
                                                <p><strong>Identificar patrones de interacción dañinos</strong> y enseñar comunicación asertiva para una mejor comprensión mutua.</p>
                                            </li>
                                            <li>
                                                <p><strong>Facilitar la resolución de conflictos</strong> enseñando técnicas para abordar problemas de forma constructiva y llegar a acuerdos.</p>
                                            </li>
                                            <li>
                                                <p><strong>Fortalecer los vínculos familiares</strong> promoviendo la empatía, el respeto y la expresión positiva de emociones.</p>
                                            </li>
                                            <li>
                                                <p><strong>Ayudar a la familia a adaptarse a cambios</strong> como la llegada de un nuevo miembro, la adolescencia o la pérdida de un ser querido.</p>
                                            </li>
                                            <li>
                                                <p><strong>Identificar y tratar problemas individuales</strong> que afectan a la familia, como la depresión o la ansiedad de un miembro.</p>
                                            </li>
                                        </ul>
                                                                          
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Terapia Infanto - Juvenil</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/psicologia-bilbao/07.Psicologo_infantil_bilbao.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Terapia Infanto - Juvenil">
                                <div class="mt-4">
                                    <h5>Terapia Infanto - Juvenil</h5>
                                                                                                            
                                        <p class="text-muted my-3">Queremos que nuestra <strong>psicóloga infantil y juvenil</strong> sea una aliada para los niños y adolescentes que enfrentan dificultades emocionales o conductuales. En nuestra consulta de psicología en Bilbao ofrecemos a los niños y adolescentes las herramientas para enfrentar desafíos emocionales y conductuales, mejorando su bienestar y desarrollo saludable. Para ello, nuestra psicóloga:</p>
                                        <ul>
                                            <li>
                                                <p><strong>Crea un espacio seguro y lúdico</strong>: A través de juegos, arte y técnicas adaptadas a la edad, el psicólogo permite a los niños expresar sus emociones y preocupaciones de forma cómoda.</p>
                                            </li>
                                            <li>
                                                <p><strong>Evalúa y diagnostica problemas</strong>: Mediante entrevistas y evaluaciones, identifica dificultades como ansiedad, depresión, TDAH o trastornos del aprendizaje.</p>
                                            </li>
                                            <li>
                                                <p><strong>Desarrolla habilidades socio-emocionales</strong>: Enseña a los niños estrategias para manejar la ira, la frustración y la ansiedad, mejorando su capacidad para relacionarse con otros.</p>
                                            </li>
                                            <li>
                                                <p><strong>Fortalece la autoestima</strong>: Ayuda a los niños y adolescentes a desarrollar una imagen positiva de sí mismos, incrementando su confianza y bienestar.</p>
                                            </li>
                                        </ul>
                                        
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Otros problemas</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/psicologia-bilbao/08_Psicologo_Bilbao_problemas_emocionales.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Problemas que te ayudamos a superar"> 
                                <div class="mt-4">
                                    <h5>Problemas que te ayudamos a superar</h5>
                                    <p class="text-muted my-3">En nuestra consulta de psicología en Bilbao, nuestra psicóloga te ayudará a superar entre otros los siguientes problemas:</p>
<ul>
    <li>
        <p><strong>Trastornos del estado de ánimo</strong>: como la depresión, el trastorno bipolar y el trastorno distímico.</p>
    </li>
    <li>
        <p><strong>Trastornos de ansiedad</strong>: como el trastorno de ansiedad generalizada, el trastorno de pánico, el trastorno obsesivo-compulsivo y las fobias.</p>
    </li>
    <li>
        <p><strong>Trastornos de la alimentación</strong>: como la anorexia nerviosa, la bulimia nerviosa y el trastorno por atracón.</p>
    </li>
    <li>
        <p><strong>Trastornos por abuso de sustancias</strong>: como el abuso de alcohol y drogas y la dependencia.</p>
    </li>
    <li>
        <p><strong>Trastornos de la personalidad</strong>: como el trastorno de personalidad límite, el trastorno narcisista de la personalidad y el trastorno de personalidad antisocial.</p>
    </li>
    <li>
        <p><strong>Trastornos psicóticos</strong>: como la esquizofrenia y el trastorno delirante.</p>
    </li>
    <li>
        <p><strong>Trastornos del desarrollo</strong>: como el trastorno por déficit de atención con hiperactividad (TDAH) y el trastorno del espectro autista.</p>
    </li>
    <li>
        <p><strong>Trastornos de aprendizaje</strong>: como la dislexia y la discalculia.</p>
    </li>
    <li>
        <p><strong>Trastornos del trauma y del estrés</strong>: como el trastorno de estrés postraumático (TEPT) y el trastorno de estrés agudo.</p>
    </li>
    <li>
        <p><strong>Problemas de relaciones</strong>: como problemas de pareja, problemas familiares y problemas de comunicación.</p>
    </li>
    <li>
        <p><strong>Problemas de estrés</strong>: enseñamos técnicas de manejo del estrés y ayudamos a identificar y abordar los factores que generan estrés.</p>
    </li>
    <li>
        <p><strong>Problemas de ira</strong>: ayudamos a comprender su origen, desarrollar mecanismos para evitarla y controlar sus arrebatos.</p>
    </li>
    <li>
        <p><strong>Gestión del dolor y pérdida</strong>: ayudamos a lidiar con el dolor y la pérdida de seres queridos, mascotas u otras experiencias traumáticas.</p>
    </li>
</ul>
<p>Es importante tener en cuenta que esta no es una lista exhaustiva de todos los problemas, sino algunos de los más comunes. Si sientes que tienes algún problema emocional o de cualquier tipo y necesitas apoyarte en un psicólogo, no dudes en contactar con nuestra psicóloga en Bilbao.</p>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    
                    
                    

                    

                    
                       

                    

                    
                </ul>
                <div [ngbNavOutlet]="nav" class=" mt-2 col-md-12"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- End -->
<!-- Start -->
<!--end section-->
<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">
    
   
    <div class="container my-2">

        <h2 class="text-center">Nuestro <strong>Centro médico en Bilbao</strong> cuenta además con otras especialidades médicas</h2>

        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4  g-4">
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/psicologia-bilbao/11_Link_Oftalmologia_bilbao.webp" class="card-img-top" alt="Oftalmología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/oftalmologo-bilbao" target="_blank">Oftalmología Bilbao</a></h5>
                 </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/psicologia-bilbao/13_Link_fisioterapia_bilbao.webp" class="card-img-top" alt="Fisioterapia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/fisioterapeuta-bilbao" target="_blank">Fisioterapia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/psicologia-bilbao/Portada_Logopeda_bilba.webp" class="card-img-top" alt="Logopedia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/logopeda-bilbao" target="_blank">Logopedia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/psicologia-bilbao/14_Link_nutrición_bilbao.webp" class="card-img-top" alt="Nutrición Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/nutricionista-bilbao" target="_blank">Nutrición Bilbao</a></h5>                
              </div>
            </div>
          </div>
        </div>
      </div>



    </section>
<!--end section-->


<!-- End -->

<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="section-title bg-white text-dark rounded border p-2 mb-3">
                        <div class="d-flex">
                            <a class="logo" routerLink="/index">
                                <img src="assets/images/medicos-billbao-psico.png" class="l-dark logo-light-mode" class="img-fluid" alt="Nutricionista Bilbao" loading="lazy">        
                              </a>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-dark mb-1">Psicología Bilbao</h4>
                                <p class="text-dark-50 mb-0">Te ayudamos en el camino hacia una vida más plena y satisfactoria.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="https://medicosbilbao.com" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"
                            data-bs-toggle="modal" data-bs-target="#appointment-request"><i
                                class="uil uil-file-alt"></i> Descubre más sobre Médicos Bilbao</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>


<!--modal contactar -->  
<div class="container">
	<div class="row g-0" >		
    <div class="nb-form">
    <p class="title"><strong>Pide tu cita aquí</strong></p>
    <img src="https://medicosbilbao.com/img/logocuadrado.png" alt="Cita Logopeda  Bilbao" class="user-icon" >
    <p class="message" ><b>Médicos Bilbao: </b>Infórmate sin compromiso. Puedes contactar con nosotros a través de estos enlaces </p>	
	<ul class="list-group">
  <li class="list-group-item"><i class="fab fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=+606573238&text=Hola,%20estoy%20interesado%20en%20pedir%20una%20cita%20...%20" target="_blank"> 606 57 32 38</a><br>(solo whatsapp) </li>
  <li class="list-group-item"><i class="fas fa-phone"></i> <a href="tel:+34944648357" style="text-decoration:none"> 944 648 357</a></li>
  <li class="list-group-item"><i class="fas fa-globe"></i><a href="https://www.doctoralia.es/clinicas/especialistas-medicos-bilbao" rel="nofollow" target="_blank" class="reserva"> Reservar cita</a></li>  
  <li class="list-group-item"><i class="fa fa-envelope" aria-hidden="true"></i> <a href="https://medicosbilbao.com/contactar" id="correo"> Correo eléctrónico</a></li>
  </ul>    
  </div>
	</div>
</div>	
<!-- FIN modal contactar -->  

<!--end container-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->