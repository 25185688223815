import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';
import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
import { AuthRePasswordThreeComponent } from './auth/auth-re-password-three/auth-re-password-three.component';
import { AuthRePasswordComponent } from './auth/auth-re-password/auth-re-password.component';
import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
import { AuthSignupComponent } from './auth/auth-signup/auth-signup.component';


import { ChangelogComponent } from './core/components/changelog/changelog.component';
import { ComponentsComponent } from './core/components/components/components.component';
import { DocumentationComponent } from './core/components/documentation/documentation.component';
import { ForumsCommentsComponent } from './core/components/forums-comments/forums-comments.component';
import { ForumsTopicComponent } from './core/components/forums-topic/forums-topic.component';
import { ForumsComponent } from './core/components/forums/forums.component';
import { HelpcenterFaqsComponent } from './core/components/helpcenter-faqs/helpcenter-faqs.component';
import { HelpcenterGuidesComponent } from './core/components/helpcenter-guides/helpcenter-guides.component';
import { HelpcenterOverviewComponent } from './core/components/helpcenter-overview/helpcenter-overview.component';
import { HelpcenterSupportRequestComponent } from './core/components/helpcenter-support-request/helpcenter-support-request.component';



import { IndexHospitalComponent } from './core/components/index-hospital/index-hospital.component';




import { MasterPageComponent } from './core/components/master-page/master-page.component';

import { ShopCartComponent } from './core/components/shop-cart/shop-cart.component';
import { ShopCheckoutsComponent } from './core/components/shop-checkouts/shop-checkouts.component';
import { ShopMyaccountComponent } from './core/components/shop-myaccount/shop-myaccount.component';
import { ShopProductDetailComponent } from './core/components/shop-product-detail/shop-product-detail.component';
import { ShopProductsComponent } from './core/components/shop-products/shop-products.component';
import { ShopListsComponent } from './core/components/shop-lists/shop-lists.component';
import { WidgetComponent } from './core/components/widget/widget.component';
import { EmailAlertComponent } from './email/email-alert/email-alert.component';
import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';

import { combineLatest } from 'rxjs/operators';
import { CorporateAboutComponent } from './core/components/corporate-about/corporate-about.component';
import { CorporateServicesComponent } from './core/components/corporate-services/corporate-services.component';
import { CorporateTeamComponent } from './core/components/corporate-team/corporate-team.component';
import { CorporatePricingComponent } from './core/components/corporate-pricing/corporate-pricing.component';
import { CorporateBlogComponent } from './core/components/corporate-blog/corporate-blog.component';
import { CorporateBlogDetailComponent } from './core/components/corporate-blog-detail/corporate-blog-detail.component';
import { CryptoAboutComponent } from './core/components/crypto-about/crypto-about.component';
import { CryptoMarketComponent } from './core/components/crypto-market/crypto-market.component';
import { CryptoTokenComponent } from './core/components/crypto-token/crypto-token.component';
import { CryptoServicesComponent } from './core/components/crypto-services/crypto-services.component';
import { CryptoFaqsComponent } from './core/components/crypto-faqs/crypto-faqs.component';
import { CryptoWhitepaperComponent } from './core/components/crypto-whitepaper/crypto-whitepaper.component';
import { CryptoBlogComponent } from './core/components/crypto-blog/crypto-blog.component';
import { CryptoBlogDetailComponent } from './core/components/crypto-blog-detail/crypto-blog-detail.component';
import { ShopAboutusComponent } from './core/components/shop-aboutus/shop-aboutus.component';
import { ShopFullwidthGridsComponent } from './core/components/shop-fullwidth-grids/shop-fullwidth-grids.component';
import { ShopGridsComponent } from './core/components/shop-grids/shop-grids.component';
import { ShopFullwidthListsComponent } from './core/components/shop-fullwidth-lists/shop-fullwidth-lists.component';
import { ShopBlogComponent } from './core/components/shop-blog/shop-blog.component';
import { ShopBlogDetailComponent } from './core/components/shop-blog-detail/shop-blog-detail.component';




import { NftExploreComponent } from './core/components/nft-explore/nft-explore.component';
import { NftCreatorsComponent } from './core/components/nft-creators/nft-creators.component';
import { CourseDetailComponent } from './core/components/course-detail/course-detail.component';
import { AuthBsLoginComponent } from './auth/auth-bs-login/auth-bs-login.component';
import { AuthLoginBgVideoComponent } from './auth/auth-login-bg-video/auth-login-bg-video.component';
import { AuthBsSignupComponent } from './auth/auth-bs-signup/auth-bs-signup.component';
import { AuthSignupBgVideoComponent } from './auth/auth-signup-bg-video/auth-signup-bg-video.component';


import { UiButtonComponent } from './core/components/ui-button/ui-button.component';
import { UiAlertComponent } from './core/components/ui-alert/ui-alert.component';
import { UiTypographyComponent } from './core/components/ui-typography/ui-typography.component';
import { UiDropdownComponent } from './core/components/ui-dropdown/ui-dropdown.component';
import { UiBackgroundComponent } from './core/components/ui-background/ui-background.component';
import { UiTextComponent } from './core/components/ui-text/ui-text.component';
import { UiBadgesComponent } from './core/components/ui-badges/ui-badges.component';
import { UiAccordionComponent } from './core/components/ui-accordion/ui-accordion.component';
import { UiCardComponent } from './core/components/ui-card/ui-card.component';
import { UiShadowComponent } from './core/components/ui-shadow/ui-shadow.component';
import { UiBorderComponent } from './core/components/ui-border/ui-border.component';
import { UiCarouselComponent } from './core/components/ui-carousel/ui-carousel.component';
import { UiBreadcrumbComponent } from './core/components/ui-breadcrumb/ui-breadcrumb.component';
import { UiPaginationComponent } from './core/components/ui-pagination/ui-pagination.component';
import { UiAvatarComponent } from './core/components/ui-avatar/ui-avatar.component';
import { UiNavTabsComponent } from './core/components/ui-nav-tabs/ui-nav-tabs.component';
import { UiModalsComponent } from './core/components/ui-modals/ui-modals.component';
import { UiTablesComponent } from './core/components/ui-tables/ui-tables.component';
import { UiIconsComponent } from './core/components/ui-icons/ui-icons.component';
import { UiProgressbarComponent } from './core/components/ui-progressbar/ui-progressbar.component';
import { UiLightboxComponent } from './core/components/ui-lightbox/ui-lightbox.component';
import { UiTooltipPopoverComponent } from './core/components/ui-tooltip-popover/ui-tooltip-popover.component';
import { UiFormComponent } from './core/components/ui-form/ui-form.component';

import { AuthBsResetComponent } from './auth/auth-bs-reset/auth-bs-reset.component';
import { AuthResetPasswordBgVideoComponent } from './auth/auth-reset-password-bg-video/auth-reset-password-bg-video.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexHospitalComponent },
      
      { path: 'changelog', component: ChangelogComponent },
      { path: 'components', component: ComponentsComponent },
      { path: 'documentation', component: DocumentationComponent },
      { path: 'forums', component: ForumsComponent },
      { path: 'forums-comments', component: ForumsCommentsComponent },
      { path: 'forums-topic', component: ForumsTopicComponent },
      { path: 'helpcenter-faqs', component: HelpcenterFaqsComponent },
      { path: 'helpcenter-guides', component: HelpcenterGuidesComponent },
      { path: 'helpcenter-overview', component: HelpcenterOverviewComponent },
      { path: 'helpcenter-support-request', component: HelpcenterSupportRequestComponent },
      
      
      { path: 'index-hospital', component: IndexHospitalComponent },
      
         
      { path: 'shop-cart', component: ShopCartComponent },
      { path: 'shop-checkouts', component: ShopCheckoutsComponent },
      { path: 'shop-myaccount', component: ShopMyaccountComponent },
      { path: 'shop-product-detail', component: ShopProductDetailComponent },
      { path: 'shop-products', component: ShopProductsComponent },
      { path: 'shop-lists', component: ShopListsComponent },
      { path: 'widget', component: WidgetComponent },
      
      { path: '#', component: SwitcherComponent },
      
      { path: 'corporate-about', component: CorporateAboutComponent },
      { path: 'corporate-services', component: CorporateServicesComponent },
      { path: 'corporate-team', component: CorporateTeamComponent },
      { path: 'corporate-pricing', component: CorporatePricingComponent },
      { path: 'corporate-blog', component: CorporateBlogComponent },
      { path: 'corporate-blog-detail', component: CorporateBlogDetailComponent },
      { path: 'crypto-about', component: CryptoAboutComponent },
      { path: 'crypto-market', component: CryptoMarketComponent },
      { path: 'crypto-token', component: CryptoTokenComponent },
      { path: 'crypto-services', component: CryptoServicesComponent },
      { path: 'crypto-faqs', component: CryptoFaqsComponent },
      { path: 'crypto-whitepaper', component: CryptoWhitepaperComponent },
      { path: 'crypto-blog', component: CryptoBlogComponent },
      { path: 'crypto-blog-detail', component: CryptoBlogDetailComponent },
      { path: 'shop-aboutus', component: ShopAboutusComponent },
      { path: 'shop-fullwidth-grids', component: ShopFullwidthGridsComponent },
      { path: 'shop-grids', component: ShopGridsComponent },
      { path: 'shop-fullwidth-lists', component: ShopFullwidthListsComponent },
      { path: 'shop-blog', component: ShopBlogComponent },
      { path: 'shop-blog-detail', component: ShopBlogDetailComponent },
      
      
      
            
      
      { path: 'nft-explore', component:  NftExploreComponent},
      { path: 'nft-creators', component:  NftCreatorsComponent},
      { path: 'course-detail', component:  CourseDetailComponent},
      
      { path: 'ui-button', component:  UiButtonComponent},
      { path: 'ui-alert', component:  UiAlertComponent},
      { path: 'ui-typography', component:  UiTypographyComponent},
      { path: 'ui-dropdown', component:  UiDropdownComponent},
      { path: 'ui-background', component:  UiBackgroundComponent},
      { path: 'ui-text', component:  UiTextComponent},
      { path: 'ui-badges', component:  UiBadgesComponent},
      { path: 'ui-accordion', component:  UiAccordionComponent},
      { path: 'ui-card', component:  UiCardComponent},
      { path: 'ui-shadow', component:  UiShadowComponent},
      { path: 'ui-border', component:  UiBorderComponent},
      { path: 'ui-carousel', component:  UiCarouselComponent},
      { path: 'ui-breadcrumb', component:  UiBreadcrumbComponent},
      { path: 'ui-pagination', component:  UiPaginationComponent},
      { path: 'ui-avatar', component:  UiAvatarComponent},
      { path: 'ui-nav-tabs', component:  UiNavTabsComponent},
      { path: 'ui-modals', component:  UiModalsComponent},
      { path: 'ui-tables', component:  UiTablesComponent},
      { path: 'ui-icons', component:  UiIconsComponent},
      { path: 'ui-progressbar', component:  UiProgressbarComponent},
      { path: 'ui-lightbox', component:  UiLightboxComponent},
      { path: 'ui-tooltip-popover', component:  UiTooltipPopoverComponent},
      { path: 'ui-form', component:  UiFormComponent},
    ]
  },

  { path: 'auth-login', component: AuthLoginComponent },
  { path: 'auth-bs-login', component: AuthBsLoginComponent },
  { path: 'auth-login-bg-video', component: AuthLoginBgVideoComponent },
  { path: 'auth-cover-login', component: AuthCoverLoginComponent },
  { path: 'auth-cover-re-password', component: AuthCoverRePasswordComponent },
  { path: 'auth-cover-signup', component: AuthCoverSignupComponent },
  { path: 'auth-login-three', component: AuthLoginThreeComponent },
  { path: 'auth-re-password', component: AuthRePasswordComponent },
  { path: 'auth-re-password-three', component: AuthRePasswordThreeComponent },
  { path: 'auth-bs-reset', component: AuthBsResetComponent },
  { path: 'auth-reset-password-bg-video', component: AuthResetPasswordBgVideoComponent },
  { path: 'auth-signup', component: AuthSignupComponent },
  { path: 'auth-bs-signup', component: AuthBsSignupComponent },
  { path: 'auth-signup-bg-video', component: AuthSignupBgVideoComponent },
  { path: 'auth-signup-three', component: AuthSignupThreeComponent },
  
  

  { path: 'email-alert', component: EmailAlertComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'email-invoice', component: EmailInvoiceComponent },
  { path: 'email-password-reset', component: EmailPasswordResetComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
