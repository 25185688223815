<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Changelog </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Docs</a></li>
                <li class="breadcrumb-item active" aria-current="page">Changelog</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Changelog Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-lg-9">
        <div class="p-4 shadow rounded border">

             <!-- v4.4.0 -->
             <h5 class="mt-5">Version <span class="text-success">4.4.0</span> - 10 December 2023 <span class="badge rounded-pill bg-danger ms-2">Latest Updated</span></h5>
             <ul class="list-unstyled">
               <li class="text-muted my-2 ms-3">
                 <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
                 <span class="fw-bold">Update :</span> Updated Angular v17.0.6
               </li>
               <li class="text-muted my-2 ms-3">
                <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
                <span class="fw-bold">Update :</span> Changed control-flow syntex
              </li>
               <li class="text-muted my-2 ms-3">
                 <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
                 <span class="fw-bold">Update :</span> Updated Packages
               </li>
               <li class="text-muted my-2 ms-3">
                 <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
                 <span class="fw-bold">Update :</span> Fixed minor issue
               </li>
             </ul>


             <!-- v4.3.0 -->
          <h5 class="mt-5">Version <span class="text-success">4.3.0</span> - 06 October 2023 </h5>
          <ul class="list-unstyled">
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Angular v16.2.8
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Packages
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Fixed minor issue
            </li>
          </ul>

          <!-- v4.2.0 -->
          <h5 class="mt-5">Version <span class="text-success">4.2.0</span> - 27th January 2023 </h5>
          <ul class="list-unstyled">
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Latest Bootstrap v5.1.3
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Packages
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Added :</span> Added Full Demo Mega menu
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Devloper Landing
              <a routerLink="/index-developer" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Corporate Business</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>About Us
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Services
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Team
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Pricing
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog Detail
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Cryptocurrency</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>About Us
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Market
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Token
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Features
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>FAQs
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Whitepaper
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog Detail
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Shop</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Fullwidth Grid
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Product Grids
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Fullwidth List
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog Detail
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Portfolio</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>About Us
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Services
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Hosting & Domain</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Domain
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Shared Hosting
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>VPS Hosting
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Dedicated Hosting
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Cloud Hosting
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Reseller Hosting
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>About Us
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Services
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>FAQs
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blog Detail
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Job</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job List One
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job List Two
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job List Three
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job List Four
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job List Five
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job Detail One
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job Detail Two
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job Detail Three
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Job Apply
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Candidate Listing
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Candidate Detail
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Company Listing
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Company Detail
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>About Us
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Pricing
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>FAQs
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Blog</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Standard Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Slider Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Gallery Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Youtube Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Vimeo Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Audio Post
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Blockquote
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Left Sidebar
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>NFT Marketplace </b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Home
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Explore
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Creators
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Account</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Chat
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Auth</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>BS5 Login
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Login Five
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>BS5 Singup
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Singup Five
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>BS5 Reset Password
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Reset Pass Five
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Course Detail
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Fixed :</span> Fixed some menu and responsive issues
            </li>

          </ul>

          <h5>Version <span class="text-success">2.6.0</span> - 17th June 2021</h5>
          <!-- v2.5.1 -->
          <ul class="list-unstyled">
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Latest Bootstrap v5.0.0 Beta 3
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Update :</span> Update Packages
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Added :</span> Added Mega menu
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Construction Landing
              <a routerLink="/index-construction" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Real Estate Landing
              <a routerLink="/index-real-estate" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Developer Landing
              <a routerLink="/index-developer" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> SEO Agency Landing
              <a routerLink="/index-seo-agency" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Hospital Landing
              <a routerLink="/index-hospital" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Finance Landing
              <a routerLink="/index-finance" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Video Conference Landing
              <a routerLink="/index-videocall" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> IT Solution Two Landing
              <a routerLink="/index-it-solution-two" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Freelance Two Landing
              <a routerLink="/index-freelancer" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Blockchain Landing
              <a routerLink="/index-blockchain" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Cryptocurrency Two Landing
              <a routerLink="/index-crypto-two" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Integration Landing
              <a routerLink="/index-integration" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Landing Four Landing
              <a routerLink="/index-landing-four" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">New :</span> Landing Four Landing
              <a routerLink="/index-landing-four" target="_blank" class="h6 text-danger">Live Preview</a>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Shop</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Product List
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Careers</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Company Listing
                </li>
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Candidate Listing
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>New Added
              <b>Special</b> Pages
              <ul class="list-unstyled">
                <li class="text-muted my-2 ms-3">
                  <i-feather name="corner-down-right" class="fea icon-sm me-2"></i-feather>Thank you
                </li>
              </ul>
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather><span class="fw-bold">Added
                :</span> Added portfolio pages
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Fixed :</span> Data render using JSON object
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Fixed :</span> Make common component for Pricing, Testimonial, Services, Blog, Team,
              Features
            </li>
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Fixed :</span> Fixed Logo issues in Dark mode and Light mode
            </li>
          </ul>

          <h5 class="mt-5">Version <span class="text-success">2.5.1</span> - 1st August 2020 </h5>
          <ul class="list-unstyled">
            <li class="text-muted my-2 ms-3">
              <i-feather name="arrow-right-circle" class="fea icon-sm me-2"></i-feather>
              <span class="fw-bold">Fixed :</span> Fixed some menu and responsive issues
            </li>
          </ul>

          <div class="mt-4">
            <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary">Purchase Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Changelog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->